.device-percentage-label {
  font: 12px sans-serif;
  font-weight: bold;
  fill: #fff;
}
.card.double {
  width: 360px;
  min-height: 140px;
}
.percentage-label {
  font: 12px sans-serif;
  font-weight: bold;
  fill: #ffffff;
}
