.axis line {
  stroke-width: 1;
  opacity: 0.2;
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.x.axis path {
  display: none;
}

.x-axis {
  font-size: 8px;
  fill: #eee;
}

.line {
  fill: none;
  stroke: #ddd;
  opacity: 0.3;
  stroke-width: 4px;
}
