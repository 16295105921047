.dash-btn {
  padding: 14px 24px;
  border-radius: 3px;
  border: 0 none;
  margin: 6px;
  font-size: 12px;
  letter-spacing: 0px;
  text-transform: uppercase;
  background: #e3be05;
  color: #fff;
  text-decoration: none;
  min-width: 100px;
  text-align: center;
}

.logout-btn {
  padding: 14px 24px;
  border-radius: 3px;
  border: 0 none;
  margin: 6px;
  font-size: 12px;
  letter-spacing: 0px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #df573e, #ff6a00);
  color: #fff;
  text-decoration: none;
  min-width: 100px;
  text-align: center;
}
