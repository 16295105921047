.zkrin-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.flex-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

body {
  background-color: #003a76;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
