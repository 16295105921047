.users-since-big-bang {
  background: no-repeat center center url("./galaxy.jpg");
  background-size: cover;
  color: white;
  text-align: center;
  justify-content: center;
  font-size: 40px;
  line-height: 60%;
  position: relative;

  .content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  &:before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: block;
    position: absolute;
  }

  .users-since-big-bang-number {
    font-size: 60px;
    font-weight: bold;
  }
  .users-since-big-bang-users {
    font-weight: 100;
    letter-spacing: 10px;
    font-size: 50px;
    padding-left: 22px;
  }
  .users-since-big-bang-text {
    font-weight: bold;
    font-size: 35px;
  }
  div {
    display: inline;
  }
}
