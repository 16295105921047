.app-details-legend {
  margin: 10px 0;
  font-size: 16px;
  color: #fff;
  opacity: 0.7;
  word-break: break-word;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 200;
}

.app-details-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  padding: 15px;
  margin: 2px;
  text-align: center;
  min-height: 200px;
}

.app-details-big-number {
  font-size: 36px;
  color: white;
  font-weight: bold;
}
