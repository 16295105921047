.android-ui-links {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.android-ui-links button {
  background: none;
  border: none;
  padding: 0;
  color: white;
  cursor: pointer;
}

.android-ui-links button:hover {
  text-decoration: underline;
}
