.datadog_card {
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2px;
  text-align: center;
  height: 200px;
  width: 400px;
}

.base-tile-background {
  background: linear-gradient(150deg, #1664a7, #15719d);
}
