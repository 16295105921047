iframe {
  border: 0;
}

.tile-link {
  text-decoration: none;
}
.zkrin-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.legend-container {
  display: flex;

  .legend-item {
    margin: 16px;
    color: white;
    font-size: 14px;
  }
}
