.legend-infos {
  margin: 10px 0;
  font-size: 16px;
  color: #fff;
  opacity: 0.7;
  word-break: break-word;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 200;

  & + & {
    margin-top: 0;
  }

  &-small {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;

    &.light {
      font-weight: normal;
      text-align: left;

      strong {
        text-decoration: underline;
      }
    }

    & + & {
      margin-top: 5px;
    }
  }
}

.card {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  padding: 15px;
  margin: 2px;
  text-align: center;

  &.tiny {
    width: 150px;
    min-height: 100px;
  }

  &.thin {
    min-height: 140px;
  }

  &.medium {
    min-height: 170px;
  }

  &.double {
    width: 430px;
    min-height: 140px;
  }

  &.large {
    width: 500px;
    height: 200px;
  }

  .big-number {
    font-size: 36px;
    font-weight: bold;
  }

  .under-big-number {
    font-size: 40px;
    line-height: 0.3em;
    opacity: 0.5;
    padding-bottom: 0.5em;
  }
  &.green {
    background-color: #00ea89;
  }
  &.blue {
    background-color: #4dc9cb;
  }
  &.yellow {
    background-color: #ffd33b;
  }
}

.stat-cell {
  color: white;
  div {
    display: inline;
  }
  span {
    display: inline-block;
  }
}
