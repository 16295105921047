iframe {
  border: 2;
  width: 300px;
  height: 100%;
}

.tile-link {
  text-decoration: none;
}
.zkrin-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.range-select {
  margin: 16px;
  height: 24px;
  border-radius: 4px;
}
