@import url("https://fonts.googleapis.com/css?family=Asap:400,700");

.factory {
  font-family: "Asap", sans-serif;

  h1 {
    font-size: 20px;
    color: #818181;
  }

  .flex-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 250px;
    padding: 15px;
    margin: 8px;
    text-align: center;

    &.super-thin {
      min-height: 107px;
      max-width: 150px;
    }

    &.thin {
      min-height: 140px;
    }

    &.medium {
      min-height: 210px;
    }

    &.large {
      width: 500px;
      height: 200px;
    }

    .thin-tile-content {
      font-size: 25px;
    }
    .tile-content {
      font-size: 50px;
    }

    &.green {
      background-color: #00ea89;
    }
    &.blue {
      background-color: #4dc9cb;
    }
    &.yellow {
      background-color: #ffd33b;
    }
    &.grey {
      background-color: #e9f3f3;
    }
  }

  h1 {
    font-family: "Asap", sans-serif;
  }
  p {
    font-family: "Asap", sans-serif;
  }

  .axis line {
    stroke-width: 1;
    opacity: 0.2;
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }

  .x.axis path {
    display: none;
  }

  .stat-cell {
    color: white;
    div {
      display: inline;
    }
    span {
      display: inline-block;
    }
    font-size: 20px;
    font-weight: bold;
  }

  .legend-text {
    font-size: 16px;
    opacity: 0.7;
    font-weight: bold;
  }
}
