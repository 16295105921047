.alert {
  color: white;
  border-radius: 10px;
  width: 80%;
  padding: 10px 50px;
  text-align: center;
  margin: 20px;
  position: relative;
}
.alert p {
  margin: 0;
}
.alert button {
  position: absolute;
  top: 0px;
  right: 0px;
}
.alert-error {
  background: red;
}
.alert-success {
  background: green;
}
.close-button {
  background: unset;
  cursor: pointer;
  border: none;
  margin: 10px;
  font-size: 20px;
  color: white;
}
